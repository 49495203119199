@font-face {
  font-family: 'Calligraffiti';
  src: url("/build/fonts/Calligraffiti.ttf"); }

main {
  height: calc(100% - 55px); }

html, article, div.container-fluid {
  height: 100%; }

body {
  background: #F5F5F1;
  font-family: "Open Sans", "Helvetica", sans-serif;
  height: 100%;
  color: #4b4b4b; }
  body ::-moz-selection {
    background: #ff8a55;
    /* WebKit/Blink Browsers */ }
  body ::selection {
    background: #ff8a55;
    /* WebKit/Blink Browsers */ }
  body ::-moz-selection {
    background: #ff8a55;
    /* Gecko Browsers */ }

a {
  color: #333333;
  outline: none; }
  a:hover {
    color: #ff6722; }

input {
  background-color: white; }

blockquote {
  font-size: 1.1em;
  font-style: italic;
  border-left: 5px solid #b9b9b9; }

h1.titulo-pagina {
  font-size: 2.3em; }

h2.subtitulo-pagina {
  margin-top: 0;
  font-size: 1.2em; }

span.separador {
  display: inline-block;
  width: 50px;
  height: 0;
  margin-bottom: 10px;
  border-bottom: 4px solid #ff6722; }

.painel-sombreado {
  font-size: 1em;
  margin: 20px 0;
  background-color: white;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 5px; }

@media (min-width: 769px) {
  .painel-sombreado {
    padding: 20px; } }

.sublinhado {
  text-decoration: underline; }

tab {
  padding-left: 4em; }

.img-inline {
  display: inline; }

.margin-auto {
  margin: auto; }

@media screen and (max-width: 768px) {
  .sem-padding-no-xs {
    padding: 0; } }

.clearfix::after {
  clear: both;
  display: block; }

.qtip {
  font-size: 12px;
  line-height: 14px;
  z-index: 9999999 !important; }

.cinza-logo {
  color: #333333; }

.cinza-claro {
  color: #c3c3c3; }

.branco {
  color: white; }

.amarelo {
  color: #ccbe02; }

.verde {
  color: green; }

.vermelho {
  color: #cb1919; }

.verde-suave {
  color: #27AE60; }

.azul {
  color: blue; }

.azul-base {
  color: #0B486B; }

.fundo-vermelho {
  background-color: #cb1919; }

.fundo-cor-primaria {
  background-color: #cb1919; }

.fundo-azul-enem {
  background-color: #ABE0F5; }

.laranja-logo {
  color: #ff6722; }

.cor-primaria {
  color: #ff6722; }

.align-middle {
  vertical-align: middle; }

.relative {
  position: relative; }

.inline {
  display: inline; }

.inline-block {
  display: inline-block; }

.conteudo-centralizado {
  text-align: center; }

.texto-na-direita {
  text-align: right; }

.carregando-conteudo-modal {
  font-size: 3em;
  text-align: center;
  margin: 1em 0; }

.nl2br {
  white-space: pre-line;
  /* considera os newline como <br> */ }

.pointer {
  cursor: pointer; }

.cursor-mover {
  cursor: move; }

.botao-facebook, .btn-login-registrar {
  display: block;
  width: 100%;
  padding: 6px;
  font-size: 1.4em;
  color: #f6f6f6;
  border: 0; }

.botao-facebook {
  background-color: #3b5998;
  margin-top: 5px; }
  .botao-facebook i.fa {
    margin-right: 20px; }
  .botao-facebook:hover {
    background-color: #384682; }

.btn-login-registrar {
  margin-bottom: 0;
  background-color: #3fb67b; }
  .btn-login-registrar:hover {
    background-color: #3aa771; }

.titulo-define-nick {
  text-align: center;
  margin-bottom: 25px;
  color: #717171; }

.input-definir-nick {
  text-align: center; }
  .input-definir-nick input {
    max-width: 500px;
    margin: auto;
    text-align: center; }

.botao-salvar-nick {
  display: block;
  text-align: center;
  margin-top: 20px; }
  .botao-salvar-nick button {
    width: 150px; }

.boxsizingBorder {
  box-sizing: border-box; }

.align-right {
  float: right; }

.text-align-left {
  text-align: left; }

.sem-margin {
  margin: 0; }

[v-cloak] {
  display: none; }

#scrollup {
  background: url("/build/figs/voltar-ao-topo.png") no-repeat;
  opacity: 0.4;
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: none;
  text-indent: -9999px; }

/* Limites do Bootstrap: sm:768px; md:992px; lg:1200px */
@media (max-width: 991px) {
  #scrollup {
    bottom: 0;
    right: 0; }
  .mostra-sm-xs {
    visibility: visible; }
  .mostra-md-lg {
    display: none; } }

@media (min-width: 992px) {
  .mostra-sm-xs {
    display: none; }
  .mostra-md-lg {
    visibility: visible; } }

#scrollup:hover {
  opacity: 1; }

.nav-superior {
  padding-bottom: 52px; }

.texto-botao-fb {
  font-size: 1.2em;
  font-weight: bold; }

.btn-facebook {
  margin-top: 15px; }

.texto-modal-confirmar {
  text-align: center;
  font-size: 1.1em; }

/* always present */
.fadecss-transition {
  transition: all .5s ease;
  opacity: 1; }

/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.fadecss-enter, .fadecss-leave {
  opacity: 0; }

ul.pagination {
  margin: 0; }
  ul.pagination > li.active > a, ul.pagination > li.active > a:hover {
    background-color: #ff6722;
    border-color: #ff6722; }
  ul.pagination > li > a, ul.pagination > li > a:hover {
    color: #ff6722; }

button.navbar-toggle[type="button"] {
  border: none;
  border-radius: 0; }
  button.navbar-toggle[type="button"] > span.icon-bar {
    background-color: white; }
  button.navbar-toggle[type="button"]:hover, button.navbar-toggle[type="button"]:focus {
    background-color: #ff6722; }

.form-control, .flavr-button {
  border-radius: 0 !important; }

.form-control:focus {
  border-color: #ff6722;
  box-shadow: 0 0 2px 0 #ff6722; }

.nav-tabs > li.active > a.pointer {
  cursor: pointer; }

.navbar {
  margin-bottom: 0;
  border: none; }

.btn {
  border-radius: 0; }

.alert {
  border-radius: 0; }

.btn-facebook {
  border-radius: 0;
  margin-top: 10px; }

.dropdown, .dropdown:hover {
  list-style-type: none;
  text-decoration: none; }

hr.message-inner-separator {
  clear: both;
  margin-top: 10px;
  margin-bottom: 13px;
  border: 0;
  height: 1px;
  background-image: -o-linear-gradient(left, transparent, rgba(0, 0, 0, 0.15), transparent); }

.lity-content {
  background-color: #06293d; }

.lightbox {
  color: rgba(255, 255, 255, 0.95);
  opacity: 1;
  padding: 20px 20px 10px;
  min-width: 380px;
  max-width: 100%;
  overflow-y: auto;
  text-align: left;
  z-index: 99999; }
  .lightbox .titulo-modalbox {
    color: #ff6722;
    text-decoration: underline;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.3em; }
  .lightbox .modal-row {
    display: block;
    margin-top: 20px;
    margin-bottom: 0; }

.lightbox.modal-generico {
  width: 600px;
  height: auto; }

.modalbox {
  background-color: #0B486B;
  color: rgba(255, 255, 255, 0.95);
  display: none;
  opacity: 1;
  padding: 20px 30px 10px;
  width: 400px;
  text-align: left;
  border-radius: 2px;
  box-shadow: 0 8px 6px -6px black; }
  .modalbox .titulo-modalbox {
    color: #ff6722;
    text-decoration: underline;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.3em; }
  .modalbox .modal-row {
    display: block;
    margin-top: 20px;
    margin-bottom: 0; }
  .modalbox .fechar-modalbox {
    margin: 0;
    padding: 0.2em;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 17px;
    background-color: #0B486B;
    border: none;
    color: #a6b2b9;
    font-size: 2em; }
    .modalbox .fechar-modalbox:hover {
      color: white;
      background-color: #5e001f; }

.botao, .botao-no-fundo-branco, .botao-hover-corprimaria, .botao-cor-primaria, .botao-desabilitado {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 1.1em;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #ff6722;
  color: #ff6722;
  background: transparent;
  transition: all ease 0.5s; }
  .botao:hover, .botao-no-fundo-branco:hover, .botao-hover-corprimaria:hover, .botao-cor-primaria:hover, .botao-desabilitado:hover {
    background-color: rgba(245, 245, 241, 0.1); }
  .botao:disabled, .botao-no-fundo-branco:disabled, .botao-hover-corprimaria:disabled, .botao-cor-primaria:disabled, .botao-desabilitado:disabled, .botao [disabled], .botao-no-fundo-branco [disabled], .botao-hover-corprimaria [disabled], .botao-cor-primaria [disabled], .botao-desabilitado [disabled] {
    cursor: not-allowed; }

.botao-no-fundo-branco:hover {
  background-color: #eaeaea; }

.botao-hover-corprimaria:hover {
  background-color: #ff6722;
  border-color: white;
  color: white; }

.botao-cor-primaria {
  background-color: #ff6722;
  border-color: white;
  color: white; }
  .botao-cor-primaria:hover {
    background-color: #ff8a55; }

.botao-pequeno {
  font-size: 0.9em;
  padding: 4px 8px; }

.botao-desabilitado {
  cursor: not-allowed; }

.botao-50-esq {
  width: 49%;
  margin-top: 3px; }

.botao-50-dir {
  width: 49%;
  margin-top: 3px;
  float: right; }

.botao-100 {
  width: 100%;
  margin-top: 3px; }

/*# sourceMappingURL=essencial.css.map */
